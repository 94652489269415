<template>
  <div style="margin: -14px -15px">
  <!--  病例分析  -->
    <a-spin :spinning="spinning">
      <div class="case">

        <!--    身份选择-->
        <div class="type-left">
          <a-menu
              style="width: 230px"
              :defaultSelectedKeys="selectId"
              mode="inline"
          >
              <a-menu-item
                  v-for="(item,index) in templateList"
                  @click="SelectIdentity(item.id,item)"
                  :key="item.id">
                {{item.title}}
              </a-menu-item>
          </a-menu>
        </div>

        <!-------------------------------   内容列表区    --------------------------------------------->
        <div class="case-right">
          <!--      下拉选择选择状态-->
          <div class="case-right-select">
            <div>
              <span>状态: </span>
              <a-select
                  v-model="selectCtatus"
                  placeholder="请选择状态"
                  style="width: 120px;"
                  @change="selectStatus">
                <a-select-option value="PASSED">
                  已通过
                </a-select-option>
                <a-select-option value="PENDING">
                  待审批
                </a-select-option>
                <a-select-option value="DENIED">
                  已驳回
                </a-select-option>
              </a-select>
            </div>
            <div style="margin-left: 20px">
              <span style="height: 35px;line-height: 30px">上传时间: </span>
              <a-range-picker
                  v-model="sum_time"
                  :allowClear="false"
                  @change="startChange"
                  style="width: 200px" />
            </div>
            <div style="margin-left: 10px">
              <a-button
                  @click="searchBtn"
                  type="primary">查询</a-button>
              <a-button
                  style="margin-left: 5px"
                  @click="returnBtn"
              >重置</a-button>
            </div>
            <div class="aleart">
              <a-icon
                  style="background-color: #1890ff;
                  height: 14px;width: 14px;
                  border-radius: 14px;
                  color: #e8e8e8"
                  type="exclamation-circle" />
                待审核：{{numberData.pendingCount}}  已通过：{{numberData.passedCount}}   已驳回：{{numberData.deniedCount}}

            </div>
          </div>
          <!--      表格     -->
          <a-table
              :columns="columns"
              :data-source="caseList"
              :pagination="false"
              style="height: auto"
              :rowKey="record=>record.id">
            <span slot="memberName" slot-scope="text,record">
              <div>{{record.memberName}} - {{record.memberHospitalName}}</div>
            </span>
            <!--状态-->
            <span slot="state" slot-scope="text,record">
                <div v-if="record.audit_status === 'PENDING'">
                  <div style="height: 30px;line-height: 30px;display: flex;">
                  <div class="div_garden"></div>
                  <div >待审批</div>
                  </div>
                </div>
                <div v-if="record.audit_status === 'PASSED'">
                  <div style="height: 30px;line-height: 30px;display: flex;">
                  <div class="div_garden_adopt"></div>
                  <div >已通过</div>
                  </div>
                </div>
                <div v-if="record.audit_status === 'DENIED'">
                  <div style="height: 30px;line-height: 30px;display: flex;">
                  <div class="div_garden_reject"></div>
                  <a @click="rejectBtn(record.reject_reason)">已驳回</a>
                  </div>
                </div>
               <div v-if="record.audit_status === 'SIGNED'">
                  <div style="height: 30px;line-height: 30px;display: flex;">
                  <div class="div_garden_adopt"></div>
                  <div >已签字</div>
                  </div>
                </div>
            </span>
            <span slot="patientName" slot-scope="text,record">
              <div>{{record.patientSex === 1 ?'男':'女'}} - {{record.patientAge}}</div>
            </span>
            <!--    时间          -->
            <span slot="created_time" slot-scope="text, record">
            <div v-if="record.created_time !== null && record.created_time !== undefined && record.created_time !== ''">
<!--              {{(record.created_time).slice(0,-9)}}-->
              {{record.created_time}}
            </div>
            <div v-else> </div>
          </span>

            <span slot="system" slot-scope="text, record">
          <div v-if="record.audit_status === 'PASSED'">
            <a @click="examineBtn(record)">审批</a>
            <a style="margin-left: 7px" @click="checkBtn(record)">查看</a>
          </div>
          <div v-if="record.audit_status === 'DENIED' || record.audit_status === 'PENDING' ">
            <a @click="examineBtn(record)">审批</a>
            <a-popconfirm
                style="margin-left: 7px"
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delectBtn(record)"
            >
            <a >删除</a>
          </a-popconfirm>
          </div>
        </span>
          </a-table>
          <div style="width: 100%;height: 50px;">
            <div style="float: right;margin-right: 20px;margin-top: 15px">
              <a-pagination
                  :current="page_no"
                  show-quick-jumper
                  @change="selectPage"
                  :total="count" />
            </div>
          </div>
        </div>
      </div>
    </a-spin>
<!------------------------  对话框  ---------------------------------->
    <a-modal
        v-model="visible"
        title="驳回原因"
        @ok="handleOk">
      <p>驳回原因: {{reject_reason}}</p>
    </a-modal>
  </div>
</template>

<script>
import {
  delCaseList,
  getCaseList,
  getmoBanList,
  getNumberList,
  getZhuangList
} from "../../../service/case_api";
import moment from "moment";
export default {
  data(){
    return{
      sum_time:[],
      no_passed:'1',
      selectId:[0],
      template_id:'',
      // audit_status:'',
      selectCtatus:undefined,
      columns:[
        {
          title: '病例 ID',
          dataIndex: 'id',
          align: 'center',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: '医生信息',
          dataIndex: 'memberName',
          align: 'center',
          scopedSlots: { customRender: 'memberName' },
        },
        // {
        //   title: '患者信息',
        //   dataIndex: "patientName",
        //   align: 'center',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'patientName' },
        // },
        {
          title: '上传时间',
          dataIndex: 'created_time',
          align: 'center',
          scopedSlots: { customRender: 'created_time' },
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'state',
          align: 'center',
          width:100,
          scopedSlots: { customRender: 'state' },
        },
        {
          title: '操作',
          align: 'center',
          dataIndex: 'system',
          ellipsis: true,
          scopedSlots: { customRender: 'system' },
        },
      ],
      caseList:[],
      count:0,
      spinning:false,
      //模板列表
      templateList:[],
      page_no:1,
      numberListData:'',
      numberData:[],
      start_time:'',
      end_time:'',
      visible:false,
      reject_reason:'',
      //存放链接
      adminLink:'',
      adminLinkKong:'',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"病例审批管理")
    //清空病例通过审核vuex中的值
    this.$store.commit('passBtn','')
    this.page_no = this.pageNoVal
  },
  mounted() {
    this.getTemplate()
    // this.getList()
    this.getNumber()
   },
  computed:{
    numReturnId(){
      return this.$store.state.returnId
    },
    //身份
    stautsValue(){
      return this.$store.state.condition
    },
  //  链接
    caseLinkVal(){
      return this.$store.state.caseLink
    },
  //  页码
    pageNoVal(){
      return this.$store.state.pageNo
    },
  },
  methods:{
    //获取id
    async getId() {
      const response = await getmoBanList()
      this.numberListData = response.data.rows[0].id;
      if(this.caseLinkVal === ''){
        this.$store.commit('caseLink',response.data.rows[0].adminLink)
      }
    },
    //获取病例模板列表
    async getTemplate(){
      const is_sh_case = 1
      let data = {
        isShCase:is_sh_case
      }
      const response = await getmoBanList(data)
      if(response.code === 0){
        const templateList= response.data.rows;
        if(this.numReturnId !== ''){
          this.selectId[0] = this.numReturnId
        }else {
          this.selectId[0]  = templateList[0].id
        }
        const template_id = this.selectId[0]
        if(this.stautsValue === 'PASSED'){
          this.no_passed = '0'
        }
        if(this.stautsValue !== undefined && this.stautsValue !== null && this.stautsValue !== ''){
          this.selectCtatus = this.stautsValue
        }else {
          this.selectCtatus = undefined
        }
        await this.getList(template_id,this.stautsValue)
        this.templateList = templateList
      }else {
        this.$message.warning("出错，请联系管理员！！" + response.message)
      }
    },
    //获取列表
    async getList(template_id,audit_status,start_time,end_time){
      this.spinning = true
        //no_passed   1显示已驳回与待审核   0 显示全部
        const no_passed = this.no_passed
        const reponse = await getCaseList(this.page_no,10,template_id,no_passed,audit_status,start_time,end_time)
        if(reponse.code === 0){
          this.caseList = reponse.data.rows
          this.count = reponse.data.count
          this.spinning = false
        }else {
          this.$message.error("获取接口失败，请联系管理员！" + reponse.message)
        }
    },
    // 分页
    async selectPage(page){
      this.page_no = page
      this.spinning = true
      await this.getList(this.selectId[0], this.selectCtatus, this.start_time, this.end_time)
      this.spinning = false
    },
    //选择身份，左侧菜单栏
    SelectIdentity(id,row) {
      //显示第一页
      this.page_no = 1
      this.adminLink = row.adminLink
      this.$store.commit('caseLink',this.adminLink)
      this.selectId[0] = id
      this.$store.commit('returnId',id)
        //  使状态复原
        this.selectCtatus = undefined
      this.$store.commit('condition','')
        //刷新界面，恢复默认不显示已通过
        this.no_passed = '1'
        this.getList(id)
        this.getNumber(id)
    },
    async getNumber(num) {
      // 获取统计
      await this.getId()
      if(num===undefined){
        if(this.numReturnId !==''){
          num = this.numReturnId
        }else {
          num = this.numberListData
        }
      }
      const response = await getNumberList(num,'auditStatus')
      this.numberData = response.data
      // if(this.numReturnId !== ''){
      //   this.$store.commit('returnId','')
      // }
    },
    //删除按钮
    async delectBtn(e) {
      //获取列表对应的id
      const list_id = e.id
      const response = await delCaseList(list_id)
      if(response.code === 0){
        //刷新列表
        await this.getList(this.selectId[0],this.selectCtatus)
        this.$message.success('已成功删除！');
      }else {
        this.$message.error('出错，请联系管理员！！' + response.message);
      }
    },
    //下拉列表-----选择状态
    async selectStatus(value) {
      this.page_no = 1
      //赋值
      this.$store.commit('condition',value)
      const template_id = this.selectId[0]
      if(value === 'PASSED'){
        this.no_passed = '0'
      }
      this.selectCtatus = value
      await this.getList(this.selectId[0],value,this.start_time, this.end_time)
    },
    //审批按钮
    examineBtn(row){
      if (this.caseLinkVal!=='chdCase'){
        //记录下page_no的页码数
        this.$store.commit('pageNo',this.page_no)
        //模板列表链接
        this.adminLink = this.caseLinkVal
        const userData = JSON.parse(window.localStorage.getItem("user_info"))
        this.$router.push({ path: `${this.adminLink}`,query:{id:row.id,user_id:userData.id,userName:userData.user_name,templeteId:this.selectId[0]}})
      }else {
        const reject_reason = row.hasOwnProperty('reject_reason')?row.reject_reason:'';
        const url='https://questionnaire.yunicu.com/#/chd/survey'+'?isSelect=false&type=shcase&id='+row.id+'&reason='+reject_reason;
        window.open(url)
      }
    },
    //查看按钮
    checkBtn(row){
      //记录下page_no的页码数
      this.$store.commit('pageNo',this.page_no)

      if(row.audit_status === 'PASSED'){
        this.$store.commit('passBtn',true)
      }
      this.adminLink = this.caseLinkVal
      const userData = JSON.parse(window.localStorage.getItem("user_info"))
      // window.open(`${this.adminLink}`+ '?id=' + row.id + '&user_id=' + userData.id)
      this.$router.push({ path: `${this.adminLink}`,query:{id:row.id,user_id:userData.id,userName:userData.userName,templeteId:this.selectId[0]}})
    },
    //开始时间
    startChange(date,dateString){
      this.start_time = dateString[0]
      this.end_time = dateString[1]
    },
    //查询
    searchBtn(){
        this.getList(this.selectId[0],this.selectCtatus,this.start_time,this.end_time)
    },
    returnBtn(){
      this.selectCtatus = undefined
      this.start_time = ''
      this.end_time = ''
      this.sum_time = []
    },
    rejectBtn(reject_reason){
      this.visible = true
      this.reject_reason = reject_reason
    },
    handleOk(){
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.caseTitle{
  font-size: 22px;
  font-weight: bold;
  color: black;
  height:100px;
  line-height: 80px;
  padding-left: 30px;
  border-bottom: 15px solid #f5f5f5;
}
.case{
  width: 100%;
  height: auto;
  display: flex;
  .type-left{
    .type-left-select{
      width: 220px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }
  }
  .case-right{
    padding: 15px;
    //height: auto;
    min-height: 700px;
    border-left: 15px solid #f5f5f5;
    .case-right-select{
      width: 100%;
      //margin-left: 85%;
      margin-right: 15%;
      padding-bottom: 15px;
      display: flex;
      .aleart{
        padding-left: 5px;
        margin-left: 20px;
        height: 32px;
        line-height: 32px;
        background-color: #eafbff;
        border-radius: 5px;
        border: 1px solid #3a9af3;
      }
    }
    .paegSty{
      width: 100%;
      margin-top: 15px;
    }
  }
}
.div_garden{
  height: 13px;
  width: 13px;
  border-radius: 13px;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #45A5E6;
}
.div_garden_adopt{
  height: 13px;
  width: 13px;
  border-radius: 13px;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #45e648;
}
.div_garden_reject{
  height: 13px;
  width: 13px;
  border-radius: 13px;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #f54141;
}

</style>
